import React from 'react';

import { Base } from '../../components/common';
import SEO from '../../components/seo';
import LoginAndRedirect from '../../components/login-with-secret/LoginAndRedirect';
import withLocation from '../../hoc/withLocation';

import SectionArc from '../../components/common/SectionArc';

import HeadTitle from '../../components/common/HeadTitle';
import OfferingItems from '../../components/common/OfferingItems';
import IntroSection from '../../components/first/sections/IntroSection';
import BooksSection from '../../components/first/sections/BooksSection';
import TestimonialSection from '../../components/first/sections/TestimonialSection';
import OfferingSection from '../../components/common/sections/OfferingSection';
import NewsletterSection from '../../components/first/sections/NewsletterSection';
import TopMessage from '../../components/first/TopMessage';
import Emoji from '../../components/common/Emoji';
import VideoRow from "../../components/first/VideoRow";

const ctaText = 'Aktivera kampanjen';
const ctaUrl = '/payment';

const CampaignPage = ({ search }) => {
  const { secret, target } = search;
  if (secret) {
    return <LoginAndRedirect stay {...{ secret, target: target || 'kampanjer/komtillbaka' }} />;
  }

  return (
    <Base {...{ ctaText, ctaUrl }}>
      <SEO title="Välkommen" />

      <IntroSection
        {...{ ctaText, ctaUrl }}
        title={<HeadTitle>Kom tillbaka - endast 7&nbsp;kr</HeadTitle>}
        ctaExtraText={<>Första månaden 7&nbsp;kr. Därefter 69&nbsp;kr&nbsp;/&nbsp;månad.</>}
        topMessage={
          <TopMessage>
            Kom tillbaka och utforska barnböckernas magiska värld med Boksnok - över 1000 barnböcker
            på ett och samma ställe <Emoji label="raketer" value="🚀🚀" />
          </TopMessage>
        }
        videoRow={
          <VideoRow>
            <OfferingItems darkMode hide14Days />
          </VideoRow>
        }
      />
      <SectionArc topColor="purple" bottomColor="white" />
      <BooksSection bgColor="white" />
      <SectionArc topColor="white" bottomColor="green" flipped />
      <TestimonialSection bgColor="green" />
      <OfferingSection
        {...{ ctaText, ctaUrl }}
        bgColor="blue-dark"
        offeringItems={<OfferingItems hide14Days />}
        price={
          <>
            <h2 className="">Första månaden 7&nbsp;kr.</h2>
            <h3 className="text-white left">Därefter 69 kr&nbsp;/&nbsp;mån</h3>
          </>
        }
      />
      <SectionArc topColor="blue-dark" bottomColor="white" />
      <NewsletterSection bgColor="white" />
    </Base>
  );
};

export default withLocation(CampaignPage);
